// import './App.css';
import { AppRouter } from "./Router";

function App() {
	return (
		<div className='App bg-black'>
			<AppRouter />
		</div>
	);
}

export default App;
