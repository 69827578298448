import { Routes, Route } from "react-router-dom";

import Landing from "./pages/landing/LandingPage";
import Gallery from "./pages/gallery/GalleryPage";
import { DebugPage } from "./pages/DebugPage";

export function AppRouter() {
	return (
		<Routes>
			<Route path='/' element={<DebugPage />} />
			<Route path='/:token' element={<Landing />} />
			<Route path='gallery' element={<Gallery />} />
		</Routes>
	);
}
