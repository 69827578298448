import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useCurrentUser } from "../store/UserProvider";

export function DebugPage() {
	const [asArtist, setAsArtist] = useState(false);
	const { setCurrentUser } = useCurrentUser();

	useEffect(() => {
		// Set default value just in case no switch between artist / user
		setCurrentUser({ id: 1 });
	}, []);

	return (
		<div className='bg-gray-600 flex justify-center items-center min-h-screen'>
			<div className=' max-w-md flex flex-col space-y-4'>
				<div className='bg-black p-10 rounded-lg shadow text-white text-center '>
					<h1 className='leading-7 pb-2 border-b mb-4 font-header'>
						Select one of the rooms
					</h1>
					<ul className=''>
						<li className='py-2 hover:bg-gray-600'>
							<Link to='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiIxIiwiYXJ0aXN0SWQiOiIxIiwiYXJ0aXN0Q2F0ZWdvcnkiOiJFbGl0ZSIsImlhdCI6MTY3MDQ5OTM3MSwiZXhwIjoxNjgxMjE5OTEyfQ.HN51ZG4NaUKoh7YHhaD3TfnQ6K8XGPCDKY3_CIDpWVI'>
								Daniel K. Winn
							</Link>
						</li>
						<li className='py-2 hover:bg-gray-600'>
							<Link to='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiIyIiwiYXJ0aXN0SWQiOiIyIiwiYXJ0aXN0Q2F0ZWdvcnkiOiJFc3RhYmxpc2hlZCIsImlhdCI6MTY3MDQ5OTM3MSwiZXhwIjoxNjgxMjE5OTEyfQ.2VJZY5l73hBg9G9YkRspEK7TscmWpiUGWP3j_jw8ajk'>
								Jamie Pettis
							</Link>
						</li>
						<li className='py-2 hover:bg-gray-600'>
							<Link to='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiIzIiwiYXJ0aXN0SWQiOiIzIiwiYXJ0aXN0Q2F0ZWdvcnkiOiJFc3RhYmxpc2hlZCIsImlhdCI6MTY3MDQ5OTM3MSwiZXhwIjoxNjgxMjE5OTEyfQ.UV8wmlxZ1Aan-D2j4wYXDpXk-BEOoeG51d-tI2cfnnY'>
								Carlos Torres
							</Link>
						</li>
						<li className='py-2 hover:bg-gray-600'>
							<Link to='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiI0IiwiYXJ0aXN0SWQiOiI0IiwiYXJ0aXN0Q2F0ZWdvcnkiOiJFc3RhYmxpc2hlZCIsImlhdCI6MTY3MDQ5OTM3MSwiZXhwIjoxNjgxMjE5OTEyfQ.T4eRczz6qWvilTusBy9CNMvNIlZYjsYcbucHmH5j-Q8'>
								Thang Dinh
							</Link>
						</li>
						<li className='py-2 hover:bg-gray-600'>
							<Link to='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb29tSWQiOiI1IiwiYXJ0aXN0SWQiOiI1IiwiYXJ0aXN0Q2F0ZWdvcnkiOiJFbGl0ZSIsImlhdCI6MTY3MDQ5OTM3MSwiZXhwIjoxNjgxMjE5OTEyfQ.3J_xdR0TbP7nv1Gm_hxDFMVu5_gUtTa5JiSc90Kz6lA'>
								Ira Bruce Reines
							</Link>
						</li>
					</ul>
				</div>
				<div className='bg-black p-4  rounded-lg shadow text-white text-center text-xs flex justify-center items-center inline-block'>
					<span className='inline-block w-12 mr-3'>Login as</span>
					<label
						htmlFor='Toggle3'
						className='p-1 inline-flex items-center rounded-full cursor-pointer dark:text-gray-200 border-gray-300 border-2'>
						<input
							id='Toggle3'
							type='checkbox'
							checked={asArtist}
							onChange={(e) => {
								const newVal = !asArtist;
								setAsArtist(newVal);
								setCurrentUser({ id: newVal ? 3 : 1 });
							}}
							className='hidden peer'
						/>
						<span className='px-2 py-1 rounded-l-full dark:bg-violet-400 peer-checked:dark:bg-transparent'>
							User
						</span>
						<span className='px-2 py-1 rounded-r-full dark:bg-transparent peer-checked:dark:bg-violet-400'>
							Artist
						</span>
					</label>
				</div>
			</div>
		</div>
	);
}
