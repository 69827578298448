import wretch from "wretch";
import { useCallback, useState } from "react";
import { useFurioos } from "../../store/FurioosProvider";
import { useEffect } from "react";
import { useMemo } from "react";
import { ThemeButton } from "../ui/ThemeButton";
import uuid from "react-uuid";
import {
	HiOutlineTrash,
	HiMagnifyingGlass,
	HiXMark,
	HiShoppingCart,
} from "react-icons/hi2";
import { useCurrentUser } from "../../store/UserProvider";

import { CollapsiblePanel } from "../ui/CollapsiblePanel";

export const Cart = () => {
	const [cartItems, setCartItems] = useState([]);
	const [showFiat, setShowFiat] = useState(false);
	const { currentUser } = useCurrentUser();
	const { sendMessage, receivedMessages, clearMessage } = useFurioos();
	const [previewImage, setPreviewImage] = useState(null);

	const currency = useMemo(() => (showFiat ? "Feat" : "Cripto"), [showFiat]);
	const currencySign = useMemo(() => (showFiat ? "$" : "Ξ"), [showFiat]);

	const fetchArt = useCallback(() => {
		const userId = currentUser.id;
		// Cripto | Cripto
		wretch(
			`https://api.timelessart.io/api/timelessart/cartList?userId=${userId}&paymentType=${currency}`
		)
			.get()
			.json((json) => {
				setCartItems(json.response_data);
			})
			.catch((error) => {
				console.log("oops");
			});
	}, [currency, currentUser]);

	useEffect(() => {
		fetchArt();
	}, [currency, currentUser, fetchArt]);

	useEffect(() => {
		if (receivedMessages.length === 0) return;
		if (receivedMessages[0].data.match(/^(ADD_CART|REMOVE_CART):(.*)$/)) {
			fetchArt();
			clearMessage(receivedMessages[0].uuid);
		}
	}, [receivedMessages, fetchArt, clearMessage]);

	const previewArtWork = (url) => {
		setPreviewImage(url);
	};
	const deleteFromCart = (artId) => {
		sendMessage(`REMOVE_CART:${artId}`);

		wretch("https://api.timelessart.io/api/timelessart/deleteToCart")
			.delete({
				artId: artId,
				userId: currentUser.id,
			})
			.json(() => {
				fetchArt();
				// setCartItems((items) => items.filter((i) => i.artId !== artId));
			});
	};

	return (
		<CollapsiblePanel
			className='fixed top-2 right-2'
			maxSize='2xl'
			icon={HiShoppingCart}>
			<h1
				className='font-header font-normal leading-7 text-2xl border-b-2 
			 border-b-gray-300 pb-2 mb-2 text-gray-300'>
				Cart contents
			</h1>
			<div className='flex flex-col divide-y divide-gray-600 divide-dotted'>
				{cartItems.map((c) => (
					<div className='flex space-x-4 items-center' key={uuid()}>
						<div className='flex flex-col space-y-2 py-2 flex-grow '>
							<div className='font-header '>{c.artData.artName}</div>
							<div className='italic text-gray-400 text-sm'>
								{c.artData.creator}
							</div>
						</div>
						<div className='font-header font-normal leading-7 text-lg '>
							{currencySign}&nbsp;
							{c.artData.artListingPrice}
						</div>
						<div className='justify-end flex'>
							<button
								className='pointer p-2 hover:bg-violet-400 rounded-lg'
								onClick={() => previewArtWork(c.artData.primaryImage)}>
								<HiMagnifyingGlass className='w-6 h-6' />
							</button>
							<button
								className='pointer p-2 hover:bg-violet-400 rounded-lg'
								onClick={() => deleteFromCart(c.artId)}>
								<HiOutlineTrash className='w-6 h-6' />
							</button>
						</div>
					</div>
				))}
			</div>
			<div className='flex justify-between items-center border-t-2 border-t-gray-300 mt-2 pt-4 flex-row-reverse'>
				<div className='text-xs flex  items-center'>
					<span className='inline-block w-12 mr-3'>Show prices in</span>
					<label
						htmlFor='Toggle3'
						className='p-1 inline-flex items-center rounded-full cursor-pointer dark:text-gray-200 border-gray-300 border-2'>
						<input
							id='Toggle3'
							type='checkbox'
							checked={showFiat}
							onChange={(e) => {
								setShowFiat(!showFiat);
							}}
							className='hidden peer'
						/>
						<span className='px-2 py-1 rounded-l-full dark:bg-violet-400 peer-checked:dark:bg-transparent'>
							Crypto
						</span>
						<span className='px-2 py-1 rounded-r-full dark:bg-transparent peer-checked:dark:bg-violet-400'>
							Fiat
						</span>
					</label>
				</div>
				<ThemeButton>Checkout</ThemeButton>
			</div>
			{previewImage !== null && (
				<div className='fixed z-50 w-screen h-screen top-0 left-0 flex justify-center items-center'>
					<div className='w-full max-w-2xl relative'>
						<img
							src={previewImage}
							alt='Artwork preview'
							className='w-full h-auto'
						/>
						<button
							className='bg-black rounded-full p-2 absolute -right-2 -top-2'
							onClick={() => setPreviewImage(null)}>
							<HiXMark className='w-6 h-6 text-white' />
						</button>
					</div>
				</div>
			)}
		</CollapsiblePanel>
	);
};
