import { useContext } from "react";
import { createContext, useState } from "react";
// import { faker } from '@faker-js/faker';

const UserContext = createContext();

// const defaultUser = {
// 	data: {
// 		uuid: faker.datatype.uuid(),
// 		name: faker.internet.userName(),
// 		email: faker.internet.email(),
// 		profileUrl: "https://picsum.photos/id/" + Math.floor(Math.random() * 1200) + "/200/200"
// 	}
// };

export function UserProvider({ children, user = null }) {
	const [currentUser, setCurrentUser] = useState(user);

	return (
		<UserContext.Provider value={{ currentUser, setCurrentUser }}>
			{children}
		</UserContext.Provider>
	);
}

export function useCurrentUser() {
	const context = useContext(UserContext);

	if (context === undefined)
		throw new Error("useCurrentUser can only be used inside UserContext");

	const { currentUser, setCurrentUser } = context;

	return { currentUser, setCurrentUser };
}
