import { useEffect } from "react";
import { useFurioos, FURIOOS_STATE } from "../../store/FurioosProvider";
import { useGallery } from "../../store/GalleryProvider";
import { useCurrentUser } from "../../store/UserProvider";

const furioosApps = {
	elite: "DYrJ6yqG3jMvP2dP7",
	established: "WJcLfBpMA9spJtMQ6",
	// elite: "MowT6sB883MWvkNv8",
	// established: "MowT6sB883MWvkNv8",
};

export function FurioosPlayer() {
	const { initPlayer, status, progress, sendMessage } = useFurioos();
	const { currentUser } = useCurrentUser();
	const { gallery } = useGallery();

	useEffect(() => {
		if (currentUser === null) return;
		if (gallery === null) return;
		let furioosID = furioosApps[gallery.type.toLowerCase()];
		initPlayer(furioosID, "furioos");
	}, [currentUser, gallery, initPlayer]);

	useEffect(() => {
		if (status !== FURIOOS_STATE.READY) return;

		sendMessage(
			`USER_ID:${currentUser.userId}|ROOM_ID:${
				gallery.roomId
			}|USER_TYPE:${currentUser.userType.toUpperCase()}`
		);
	}, [status, sendMessage, gallery, currentUser]);

	useEffect(() => {
		console.log({ status, progress });
	}, [status, progress]);

	return (
		<>
			{/* Furioos webstream */}
			<div id='furioos' className='h-screen w-screen'></div>
			{/* Hide webstream with overlay while loading */}
			{status !== FURIOOS_STATE.READY && (
				<>
					<div className='absolute left-0 top-0 w-full h-full bg-black bg-opacity-70' />
					{status === FURIOOS_STATE.LOADING && (
						<div className='absolute left-0 top-0 w-full h-full flex items-center justify-center '>
							<div className='bg-black text-white rounded-md p-10'>
								Pct loaded: {progress}
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
}
