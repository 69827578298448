export function ThemeButton({ children, classProp = '', primary = true, ...rest }) {
	return (
		<button
			className={`rounded-full px-5 py-1 border font-semibold ${classProp} ${
				primary
					? "bg-white border-white text-black"
					: "border-white text-white bg-black"
			}`}
			{...rest}>
			{children}
		</button>
	);
}
