import { Component, useMemo, useState } from "react";
import { HiXMark as XMarkIcon } from "react-icons/hi2";

export const CollapsiblePanel = ({
	className,
	icon = null,
	label = false,
	badge = false,
	maxSize = "5xl",
	hideBadgeOnOpen = true,
	children,
	...rest
}) => {
	const [open, setOpen] = useState(false);
	const Icon = icon;
	const containerStyle = useMemo(() => (label ? " w-16 h-16" : ""), [label]);

	return (
		<>
			{!open && (
				<div className={`${className}`}>
					<button
						onClick={() => setOpen(true)}
						className={`bg-black rounded-full p-3 flex flex-col justify-center items-center ${containerStyle}  relative`}>
						<Icon className='w-6 h-6 text-white' />
						{label !== false && (
							<span className='text-sm text-white block'>{label}</span>
						)}
						{badge !== false && (
							<span className='bg-red-600 text-xs w-6 h-6 flex justify-center items-center text-white rounded-full absolute -top-2 -right-2'>
								{badge}
							</span>
						)}
					</button>
				</div>
			)}
			{open && (
				<div className='fixed w-screen h-screen bg-black bg-opacity-30 flex justify-center items-center'>
					<div
						className={`w-full max-w-${maxSize} relative bg-black rounded-lg`}>
						<button
							className='bg-black rounded-full p-2 absolute -right-2 -top-2'
							onClick={() => setOpen(false)}>
							<XMarkIcon className='w-6 h-6 text-white' />
						</button>
						<div className='m-4 overflow-y-auto overflow-x-hidden'>
							{children}
						</div>
					</div>
				</div>
			)}
		</>
	);
};
