import { useContext } from "react";
import { createContext, useState } from "react";

const GalleryContext = createContext();
// const defaultGallery = {
// 	data: {
// 		uuid: faker.datatype.uuid(),
// 		name: faker.internet.GalleryName(),
// 		email: faker.internet.email(),
// 		profileUrl: "https://picsum.photos/id/" + Math.floor(Math.random() * 1200) + "/200/200"
// 	}
// };

export function GalleryProvider({ children, existingGallery = null }) {
	const [gallery, setGallery] = useState(existingGallery);

	return (
		<GalleryContext.Provider value={{ gallery, setGallery }}>
			{children}
		</GalleryContext.Provider>
	);
}

export function useGallery() {
	const context = useContext(GalleryContext);

	if (context === undefined)
		throw new Error("useGallery can only be used inside GalleryContext");

	const { gallery, setGallery } = context;

	return { gallery, setGallery };
}
