import { Link } from "react-router-dom";
import { useJwt } from "react-jwt";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "../../store/UserProvider";
import { useEffect } from "react";
import { useGallery } from "../../store/GalleryProvider";
import { useCallback } from "react";
import { useState } from "react";
// import { FurioosPlayer } from "../../components/FurioosPlayer";

const IdleState = () => {
	return (
		<>
			{/* <div className='p-3 flex justify-between'>
				<h1 className='mb-2 font-bold'>Welcome!</h1>
				<Link to='/gallery' className='btn button-primary'>
					Go to gallery
				</Link>
			</div> */}
			Fetching user data
		</>
	);
};

const ExpiredState = () => <>Login expired</>;
const UserUnkownState = () => <>User unknown</>;
const ConnectingState = () => <>Connecting...</>;

const pages = {
	IDLE: <IdleState />,
	USER_UNKOWN: <UserUnkownState />,
	EXPIRED: <ExpiredState />,
	CONNECTING: <ConnectingState />,
};

export default function LandingPage() {
	const { token } = useParams();
	const navigate = useNavigate();
	const { currentUser, setCurrentUser } = useCurrentUser();
	const { setGallery } = useGallery();

	const { decodedToken, isExpired } = useJwt(token);

	const [pageState, setPageState] = useState("IDLE");

	const accessGranted = useCallback(
		async (info) => {
			// fetch user information
			const userId = currentUser.id;
			const userInfoCall = await fetch(
				`https://api.timelessart.io/api/timelessart/users?userId=${userId}`
			);
			if (!userInfoCall.ok) {
				return setPageState("USER_UNKNOWN");
			}
			const userInfo = await userInfoCall.json();

			if (userInfo.response_data.length === 0)
				return setPageState("USER_UNKNOWN");

			setCurrentUser((user) => ({
				...user,
				uuid: info.artistId,
				...userInfo.response_data[0],
			}));
			// fetch gallery information
			setGallery({ roomId: info.roomId, type: info.artistCategory });
			navigate(`/gallery`);
		},
		[navigate, setCurrentUser, setGallery]
	);

	useEffect(() => {
		if (decodedToken === null) return;

		if (isExpired) {
			setPageState("EXPIRED");
			return;
		}
		setPageState("CONNECTING");
		accessGranted(decodedToken);
	}, [decodedToken, isExpired, accessGranted]);

	return (
		<div className='bg-gray-600 min-h-screen relative flex justify-center items-center'>
			<div className='bg-gray-800 text-white p-10 shadow-lg text-center'>
				{pages[pageState]}
			</div>
		</div>
	);
}
