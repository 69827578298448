import * as React from "react";

function Minus({ className = "", ...rest }) {
	return (
		<svg viewBox="0 0 448 512" className={`fill-current ${className}`} {...rest}>
			<path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/>
		</svg>
	);
}

const MemoMinus = React.memo(Minus);
export default MemoMinus;
