import { useCallback, useRef, useState } from "react";
import { Player, FS_SDK_EVENTS_NAME } from "furioos-sdk";
import { useInterval, useTimeout } from "react-use-timeout";
import { useEffect } from "react";
import { createContext } from "react";
import { useContext } from "react";
import uuid from "react-uuid";

export const FURIOOS_STATE = {
	UNITIALIZED: "UNINITIALIZED",
	CONNECTING: "CONNECTING",
	UNAVAILABLE: "UNAVAILABLE",
	LOADING: "LOADING",
	IDLE: "IDLE",
	READY: "READY",
	STOPPED: "STOPPED",
};

const defaultOptions = {
	whiteLabel: true,
	hideToolbar: false,
	hideTitle: true,
	hidePlayButton: false,
	inactiveTimeout: 60000,
	incomingMessageKey: "message",
};

const FurioosContext = createContext();

export function FurioosProvider({ children }) {
	const [status, setStatus] = useState(FURIOOS_STATE.IDLE);
	const [progress, setProgress] = useState(0);
	const playerRef = useRef(null);
	const [receivedMessages, setReceivedMessages] = useState([]);

	const checkServerAvailability = useCallback(() => {
		playerRef.current.getServerAvailability(
			(e) => {
				if (e.availableMachines > 0) {
					resetInvitesTimeout.stop();
					startPlayer();
				} else {
					resetInvitesTimeout.start();
				}
			},
			(e) => {
				console.log("getServerAvailability error", e);
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resetInvitesTimeout = useTimeout(checkServerAvailability, 30000);

	const startPlayer = useCallback(() => {
		setProgress(0);
		setStatus(FURIOOS_STATE.LOADING);
		playerRef.current.start();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const stopPlayer = useCallback(() => {
		setStatus(FURIOOS_STATE.STOPPED);
		playerRef.current.stop();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const maximizePlayer = useCallback(() => {
		playerRef.current.maximize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sendMessage = useCallback((msg) => {
		playerRef.current.sendSDKMessage(msg);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearMessage = useCallback((uuid) => {
		setReceivedMessages(receivedMessages.filter((f) => f.uuid !== uuid));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearMessages = useCallback(() => {
		setReceivedMessages([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initPlayer = useCallback((appId, containerId, instanceOptions = {}) => {
		if (playerRef.current !== null) {
			console.error("PLAYER ALREADY INITIALIZED");
			return;
		}

		const options = { ...defaultOptions, ...instanceOptions };

		const player = new Player(appId, containerId, options);

		// Bind player loaded
		player.on(FS_SDK_EVENTS_NAME.LOAD, function () {
			// console.log("SDK client FIRED: Player loaded");
			playerRef.current.getServerAvailability(
				(e) => {
					// console.log("getServerAvailability success", e);
					// startPlayer();

					if (e.availableMachines === 0) {
						setStatus(FURIOOS_STATE.UNAVAILABLE);
						resetInvitesTimeout.start();
					} else {
						startPlayer();
					}
				},
				(e) => {
					console.log("getServerAvailability error", e);
				}
			);
		});

		// Bind application install progress
		player.on(FS_SDK_EVENTS_NAME.ON_APP_INSTALL_PROGRESS, function (data) {
			console.log("SDK client FIRED: App install progress", data);
			setProgress(data.progress);
		});

		// Bind application start
		player.on(FS_SDK_EVENTS_NAME.ON_APP_START, function () {
			console.log("SDK client FIRED: App start");
			setProgress(100);
		});

		// Bind stream start
		player.on(FS_SDK_EVENTS_NAME.ON_STREAM_START, function () {
			console.log("SDK client FIRED: Stream start");
			setStatus(FURIOOS_STATE.CONNECTING);
		});

		// Bind stream start
		player.on(FS_SDK_EVENTS_NAME.ON_SDK_START, function () {
			console.log("SDK client FIRED: SDK start");
			setStatus(FURIOOS_STATE.READY);
		});

		// Bind SDK messages
		player.on(FS_SDK_EVENTS_NAME.ON_SDK_MESSAGE, function (data) {
			console.log("SDK Message Received:", data);
			let newMessage = { uuid: uuid(), data: "" };
			if (typeof data === "string") newMessage.data = data;
			else if (data.hasOwnProperty(options.incomingMessageKey))
				newMessage = { data: data[options.incomingMessageKey] };
			setReceivedMessages((messages) => [...messages, newMessage]);
		});

		// Bind an event that lets you know if you can resume session
		player.on(
			FS_SDK_EVENTS_NAME.ON_RESUME_SESSION,
			function ({ canResumeSession }) {
				if (canResumeSession) {
					player.resumeSession();
				}
			}
		);

		// Bind session stoppeds
		player.on(FS_SDK_EVENTS_NAME.ON_SESSION_STOPPED, function () {
			console.log("SDK client FIRED: Session Stopped");
		});

		playerRef.current = player;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FurioosContext.Provider
			value={{
				initPlayer,
				startPlayer,
				stopPlayer,
				sendMessage,
				maximizePlayer,
				clearMessages,
				status,
				progress,
				receivedMessages,
				clearMessage,
			}}>
			{children}
		</FurioosContext.Provider>
	);
}

export function useFurioos() {
	const context = useContext(FurioosContext);
	if (context === undefined)
		throw new Error("useGallery can only be used inside GalleryContext");

	return context;
}
