import React, { useState } from "react";

import MyChat from "../../components/chat/MyChat";
import { BsChatDotsFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

import { Onboarding } from "./Onboarding";
import { FurioosPlayer } from "../../components/streaming/FurioosPlayer";
import { useEffect } from "react";
import { useFurioos } from "../../store/FurioosProvider";
import { PrettyPrint } from "../../components/utils/debugging/PrettyPrint";
import { AppLayer, APPLAYER_ORDER } from "../../components/ui/AppLayer";
import { Cart } from "../../components/cart/Cart";
import { ChatComponent } from "../../components/chat/ChatComponent";
import { ToastContainer } from "react-toastify";

export default function GalleryPage() {
	const { receivedMessages } = useFurioos();

	useEffect(() => {
		console.log("Updated", receivedMessages);
	}, [receivedMessages]);

	return (
		<div className='bg-gray-100 min-h-screen relative'>
			<AppLayer order={APPLAYER_ORDER.EXPERIENCE}>
				<FurioosPlayer />
			</AppLayer>
			<AppLayer order={APPLAYER_ORDER.BLOCKING}>
				<Onboarding />
			</AppLayer>
			{/*<header className='p-3 flex justify-between'>
				<h1 className='mb-2 font-bold'>Gallery page</h1>
				<Link to='/' className='button-primary'>
					Back
				</Link>
			</header>*/}
			<AppLayer order={APPLAYER_ORDER.INTERFACE}>
				<>
					<ChatComponent />
					<Cart />
				</>
			</AppLayer>
			{/* <AppLayer order={APPLAYER_ORDER.DEBUG}>
				<div className='min-h-96 max-h-full overflow-y-auto w-1/2 absolute right-0 bottom-0 bg-gray-800 p-10 shadow-lg text-white z-50'>
					<PrettyPrint title='User info' data={currentUser} />
					<PrettyPrint title='Gallery info' data={gallery} />
					<details>
						<summary>
							<h1 className='font-semibold inline-block'>Received messages:</h1>
						</summary>
						{receivedMessages.map((msg) => (
							<div key={uuid()}>{msg}</div>
						))}
					</details>
				</div>
			</AppLayer> */}
			<ToastContainer />
		</div>
	);
}
