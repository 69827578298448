import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
// import { faker } from '@faker-js/faker';
// import PubNub from "pubnub";
// import { PubNubProvider } from "pubnub-react";
import { UserProvider } from "./store/UserProvider";
import { GalleryProvider } from "./store/GalleryProvider";
import { FurioosProvider } from "./store/FurioosProvider";

// Import fonts
// import "./assets/fonts/Roboto-Regular.ttf";
// import "./assets/fonts/EurostileExtended.ttf";
// import "./assets/fonts/EurostileExtendedBlack.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));
/*
//let { uuid } = useParams();
const currentUser = {
	data: {
		uuid: faker.datatype.uuid(),
		name: faker.internet.userName(),
		email: faker.internet.email(),
		profileUrl: "https://picsum.photos/id/"+Math.floor(Math.random() * 1200)+"/200/200"
	}
};

const pubnub = new PubNub({
	publishKey: process.env.REACT_APP_PUBNUB_PUB,
	subscribeKey: process.env.REACT_APP_PUBNUB_SUB,
	// userId: "91b22c15-b8b1-4c2d-95c4-d226dc46ae1f", // Ernie
	// userId: "2821d6a4-0b42-4df8-9606-c70d9f621628", // Mireya
	// userId: "47ca4f0e-a1e6-413f-b9f3-8b7500dced41", // Raphael Miller
	// userId: "0e4f8ec6-f328-45c9-a861-32e43528095f", // Mathilda89
	userId: uuid ? uuid : currentUser.data.uuid,
});

if (!uuid && currentUser) { // if new user
	pubnub.objects.setUUIDMetadata({
		data: currentUser.data
	});

// Link the current user to the default channel, if not linked yet
	pubnub.objects.setMemberships({
		uuid: currentUser.data.uuid,
		channels: ["gallery"]
	});
}
*/

root.render(
	<React.StrictMode>
		{/*<PubNubProvider client={pubnub}>*/}
		<BrowserRouter>
			<UserProvider>
				<GalleryProvider>
					<FurioosProvider>
						<App />
					</FurioosProvider>
				</GalleryProvider>
			</UserProvider>
		</BrowserRouter>
		{/*</PubNubProvider>*/}
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
