import React, { useState, useEffect, useCallback, MouseEvent } from "react";
import { BaseObjectsEvent } from "pubnub";
import pickerData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { usePubNub } from "pubnub-react";
import {
  // ChannelEntity,
  ChannelList,
  Chat,
  MemberList,
  // MessageEnvelope,
  MessageInput,
  MessageList,
  TypingIndicator,
  useChannelMembers,
  useChannels,
  usePresence,
  useUser,
  useUserMemberships,
  useUsers,
} from "@pubnub/react-chat-components";
import { CreateChatModal } from "./create-chat-modal";
import { PublicChannelsModal } from "./public-channels-modal";
import "../../scss/moderated-chat.scss";
import "../../scss/custom-chat.scss";
import { IoMdClose } from 'react-icons/io';
import { IoAddCircleOutline } from 'react-icons/io5';
import { RiArrowRightSFill } from 'react-icons/ri';
import { BiBrightnessHalf } from 'react-icons/bi';
import { AiOutlineSearch } from 'react-icons/ai';
import { MdOutlineLogout } from "react-icons/md";

const defaultChannel = {
  id: "gallery",
  name: "Gallery",
  description: "This is the default channel",
};

export default function MyChat() {
  const [theme, setTheme] = useState("light");

   /**
   * All data related to Users, Channels and Memberships is stored within PubNub Objects API
   * It can be easily accessed using React Chat Components hooks
   */
  const pubnub = usePubNub();
  const uuid = pubnub.getUUID();
  const [currentUser] = useUser({ uuid,  include: { customFields: true } });
  const [allUsers] = useUsers({ include: { customFields: true } });
  const [allChannels] = useChannels({ include: { customFields: true } });
  const [joinedChannels, , refetchJoinedChannels] = useUserMemberships({ include: { channelFields: true, customChannelFields: true },
  });
  const [currentChannel, setCurrentChannel] = useState(defaultChannel);
  const [showMembers, setShowMembers] = useState(false);
  const [showChannels, setShowChannels] = useState(true);
  const [showPublicChannelsModal, setShowPublicChannelsModal] = useState(false);
  const [showCreateChatModal, setShowCreateChatModal] = useState(false);
  const [channelsFilter, setChannelsFilter] = useState("");
  const [membersFilter, setMembersFilter] = useState("");

  useEffect(() => {
    pubnub.objects.setChannelMetadata({
      channel: "gallery",
      data: {
        name: "Gallery",
        description: "This is the main channel.",
      }
  });
  }, []);

  console.log(currentUser, {uuid}, currentChannel); // ok
  // console.log(allUsers); //ok

  const [channelMembers, , refetchChannelMemberships, totalChannelMembers] = useChannelMembers({
    channel: currentChannel.id,
    include: { customUUIDFields: true },
  });

  const [presenceData] = usePresence({
    channels: joinedChannels.length ? joinedChannels.map((c) => c.id) : [currentChannel.id],
  });

  /**
   * Some of the data related to current channel, current user and its' joined channels
   * has to be filtered down and mapped from the hooks data
   */
  const presentUUIDs = presenceData[currentChannel.id]?.occupants?.map((o) => o.uuid);
  const groupChannels = joinedChannels.filter(
    (c) =>
        (c.id?.toLowerCase() === "gallery" || c.id?.startsWith("space.")) && c.name?.toLowerCase().includes(channelsFilter.toLowerCase())
  );
  const groupChannelsToJoin = allChannels.filter(
    (c) => (c.id?.toLowerCase() === "gallery" || c.id?.startsWith("space.")) && !joinedChannels.some((b) => c.id === b.id)
  );

  // console.log('groupChannels', groupChannels);
  // console.log('groupChannelsToJoin', groupChannelsToJoin);

  const directChannels = joinedChannels
    /*.filter((c) => c.id?.startsWith("direct.") || c.id?.startsWith("group."))
    .map((c) => {
        if (!c.id?.startsWith("direct.")) return c;
        const interlocutorId = c.id.replace(uuid, "").replace("direct.", "").replace("@", "");
        const interlocutor = allUsers.find((u) => u.id === interlocutorId);
        if (interlocutor) {
          c.custom = { profileUrl: interlocutor.profileUrl || "" };
          c.name = interlocutor.name;
        }
        return c;
      })
      .filter((c) => c.name?.toLowerCase().includes(channelsFilter.toLowerCase()));*/

    .filter((c) => c.id.endsWith("__private") || c.id?.startsWith("group."))
    .map((c) => {
      if (!c.id.endsWith("__private")) return c;
      const interlocutorId = c.id.replace(uuid, "").replace("__private", "").replace("@", "");
      const interlocutor = allUsers.find((u) => u.id === interlocutorId);
      if (interlocutor) {
        c.custom = { profileUrl: interlocutor.profileUrl || "" };
        c.name = interlocutor.name;
      }
      return c;
    })
    .filter((c) => c.name?.toLowerCase().includes(channelsFilter.toLowerCase()));
   // console.log('joinedChannels', joinedChannels);
   // console.log('directChannels', directChannels);

  /**
   * Creating and removing channel memberships (not subscriptions!)
   */
  const leaveChannel = async (channel: ChannelType, event: MouseEvent) => {
    event.stopPropagation();
    await pubnub.objects.removeMemberships({ channels: [channel.id] });
    setAnotherCurrentChannel(channel.id);
  };

  const setAnotherCurrentChannel = (channelId: string) => {
    if (currentChannel.id === channelId) {
      const newCurrentChannel = joinedChannels?.find((ch) => ch.id !== channelId);
      if (newCurrentChannel) setCurrentChannel(newCurrentChannel);
    }
  };

  const refreshMemberships = useCallback(
    (event: BaseObjectsEvent) => {
      // if (event.channel.startsWith("user_")) refetchJoinedChannels();
      if (event.channel.endsWith("__private")) refetchJoinedChannels();
      if (event.channel === currentChannel.id) refetchChannelMemberships();
    },
    [currentChannel, refetchJoinedChannels, refetchChannelMemberships]
  );

  /*useEffect(() => {
    if (currentChannel.id !== "gallery" && joinedChannels.length)
      setCurrentChannel(joinedChannels[0]);
  }, [currentChannel, joinedChannels]);*/

  return (
      <div className={`app-moderated app-moderated--${theme}`}>
        <Chat
            theme={theme}
            users={allUsers}
            currentChannel={currentChannel.id}
            channels={[currentChannel.id, ...joinedChannels.map((ch) => `${ch.id}-pnpres`)]}
            // channels={[...joinedChannels.map((c) => c.id), uuid]}
            onMembership={(e) => refreshMemberships(e)}
        >
          {showPublicChannelsModal && (
              <PublicChannelsModal
                  {...{
                    groupChannelsToJoin,
                    hideModal: () => setShowPublicChannelsModal(false),
                    setCurrentChannel,
                  }}
              />
          )}
          {showCreateChatModal && (
              <CreateChatModal
                  {...{
                    currentUser,
                    hideModal: () => setShowCreateChatModal(false),
                    setCurrentChannel,
                    users: allUsers.filter((u) => u.id !== uuid),
                  }}
              />
          )}

          {/*Channels & chats*/}
          <div className="channels-panel">
            <div className="user-info">
              {currentUser && <MemberList members={[currentUser]} selfText="" />}
            </div>

            <div className="theme-switcher">
              <i className="material-icons-outlined"><BiBrightnessHalf /></i>
              <button
                  className={theme}
                  onClick={() => setTheme(theme === "light" ? "dark" : "light")}
              >
                <span></span>
              </button>
            </div>

            <div className="filter-input">
              <input
                  onChange={(e) => setChannelsFilter(e.target.value)}
                  placeholder="Search in..."
                  type="text"
                  value={channelsFilter}
              />
              <i className="material-icons-outlined"><AiOutlineSearch /></i>
            </div>

            <div className="channel-lists">
              <h2>
                Channels{" "}
                <button
                    className="material-icons-outlined"
                    onClick={() => setShowPublicChannelsModal(true)}
                >
                  <IoAddCircleOutline />
                </button>
              </h2>
              <div>
                <ChannelList
                    channels={groupChannels}
                    onChannelSwitched={(channel) => setCurrentChannel(channel)}
                    extraActionsRenderer={(c) => (
                      <div onClick={(e) => leaveChannel(c, e)} title="Leave channel">
                        <i className="material-icons-outlined small"><MdOutlineLogout /></i>
                      </div>
                    )}
                  />
              </div>

              <h2>
                Direct chats{" "}
                <button
                    className="material-icons-outlined"
                    onClick={() => setShowCreateChatModal(true)}
                  >
                    <IoAddCircleOutline />
                  </button>
              </h2>
              <div>
                <ChannelList
					channels={directChannels}
					onChannelSwitched={(channel) => setCurrentChannel(channel)}
					extraActionsRenderer={(c) => (
					  <div onClick={(e) => leaveChannel(c, e)} title="Leave channel">
						<i className="material-icons-outlined small"><MdOutlineLogout /></i>
					  </div>
					)}
				  />
              </div>
            </div>
          </div>

          {/*Chat window*/}
          <div className="chat-window">
            <div className="channel-info">
              {!currentChannel.id.endsWith("__private") && (
                  <button
                    className="mobile material-icons-outlined"
                    onClick={() => setShowChannels(true)}
                >
                  menu
                </button>
              )}

              {!currentChannel.id.endsWith("__private") && (
                <span onClick={() => setShowMembers(!showMembers)}>
                  <div className="flex items-center font-bold">
                    {currentChannel.name || currentChannel.id}
                    <i className="material-icons-outlined"><RiArrowRightSFill /></i>
                  </div>
                  <p>{totalChannelMembers} members</p>
                </span>
              )}

              {currentChannel.id.endsWith("__private") && (<>
                  <div className="flex items-center font-bold">
                    {currentChannel.name || currentChannel.id}
                  </div>
                  <p>{totalChannelMembers} members</p>
              </>)}

              <hr />
            </div>

            <MessageList
                fetchMessages={20}
                enableReactions={true}
                reactionsPicker={<Picker data={pickerData} theme={theme} />}
            />
            <TypingIndicator />
            <hr />
            <MessageInput
                typingIndicator
                // fileUpload="image"
                emojiPicker={<Picker data={pickerData} theme={theme} />}
                placeholder={"Send message"}
            />
          </div>

          {/*Members*/}
          <div className={`"members-panel ${showMembers ? "shown" : "hidden"}`}>
            <h2>
              Members
              <button className="material-icons-outlined" onClick={() => setShowMembers(false)}>
                <IoMdClose />
              </button>
            </h2>
            <div className="filter-input">
              <input
                  onChange={(e) => setMembersFilter(e.target.value)}
                  placeholder="Search in members"
                  type="text"
                  value={membersFilter}
              />
              <i className="material-icons-outlined"><AiOutlineSearch /></i>
            </div>
            <MemberList
                members={channelMembers.filter((c) =>
                    c.name?.toLowerCase().includes(membersFilter.toLowerCase())
                )}
                presentMembers={presentUUIDs}
            />
          </div>
        </Chat>
      </div>
  );
}