import { useEffect, useReducer, useState } from "react";
import { Keyboard, Mouse, Dollar, Minus, Play, User } from "../../assets/icons";
import { ThemeButton } from "../../components/ui/ThemeButton";
import { IoClose } from "react-icons/io5";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";

const initializeStep = (start) => ({ step: start });

const TIPS = {
	START: "start",
	NEXT: "next",
	PREVIOUS: "previous",
};

function stepReducer(state, action) {
	switch (action.type) {
		case TIPS.START:
			return { step: 0 };
		case TIPS.NEXT:
			return { step: state.step + 1 };
		case TIPS.PREVIOUS:
			return { step: state.step - 1 };
		default:
			throw new Error();
	}
}

export function Onboarding({ className = "", ...rest }) {
	const [state, dispatch] = useReducer(stepReducer, -1, initializeStep);
	const [showModal, setShowModal] = useState(true);
	const [slidesContent, setSlidesContent] = useState(null);

	useEffect(() => {
		fetch("https://api.timelessart.io/api/timelessart/findTutorial")
			.then((response) => response.json())
			.then((data) => {
				if (data.response_data) {
					const content = data.response_data;

					if (content.length > 0) {
						// console.log("content", content);
						setSlidesContent(content);
					}
				}
			});
	}, []);

	const getIcon = (input) => {
		if (input.indexOf("artist") > -1) return <User className='w-9' />;
		if (input.indexOf("play") > -1) return <Play className='w-9' />;
		if (input.indexOf("purchase") > -1) return <Dollar className='w-9' />;
	};

	return (
		<>
			{showModal && (
				<div className='fixed bg-black bg-opacity-50 w-screen h-screen flex justify-center items-center'>
					{state.step < 0 && slidesContent && (
						<>
							<div className='bg-black rounded-2xl w-full mx-4 max-w-sm text-white h-500 relative'>
								<div className='flex justify-end p-2'>
									<IoClose
										className='cursor-pointer'
										onClick={() => setShowModal(false)}
									/>
								</div>

								<div className='px-20 pb-14'>
									<h1 className='font-header text-lg'>
										{slidesContent[0].title}
									</h1>
									<div className='text-sm my-20'>
										{slidesContent[0].desc.split("\n").map((str, index) => (
											<p key={`text-${index + 1}`}>
												{str}
												<br />
											</p>
										))}
									</div>
								</div>

								<div className='absolute bottom-14 w-full'>
									<ThemeButton
										onClick={() => dispatch({ type: TIPS.START })}
										classProp='flex mx-auto'
										primary>
										Continue
									</ThemeButton>
								</div>
							</div>
						</>
					)}

					{state.step >= 0 && slidesContent && slidesContent.length > 1 && (
						<>
							<div className='bg-black rounded-2xl w-full mx-4 max-w-5xl text-white flex overflow-hidden h-500 relative'>
								<div
									className='bg-white w-3/5'
									style={{
										backgroundImage: `url(${
											slidesContent[state.step + 1].gifUrl
										})`,
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center",
									}}>
									{state.step > 0 && (
										<SlArrowLeft
											className='absolute text-black top-1/2 left-3 hover:text-gray-500 cursor-pointer'
											onClick={() => {
												dispatch({ type: TIPS.PREVIOUS });
											}}
										/>
									)}
								</div>

								<div className='w-2/5 relative'>
									{state.step < slidesContent.length - 2 && (
										<SlArrowRight
											className='absolute top-1/2 right-3 hover:text-gray-500 cursor-pointer'
											onClick={() => {
												dispatch({ type: TIPS.NEXT });
											}}
										/>
									)}

									<div className='flex justify-end p-2'>
										<IoClose
											className='cursor-pointer'
											onClick={() => setShowModal(false)}
										/>
									</div>

									<div className='px-16 pb-14'>
										<h1 className='font-header font-normal leading-7 text-2xl'>
											{slidesContent[state.step + 1].title}
										</h1>

										{state.step === 0 &&
											slidesContent[state.step + 1].desc &&
											slidesContent[state.step + 1].desc
												.split("\n\n")
												.map((desc, index) => (
													<div
														key={`desc-${state.step + 1}${index + 1}`}
														className='flex-col'>
														{index === 0 && (
															<div className='flex items-center space-x-10 mt-16'>
																<div className='w-1/3'>
																	<Keyboard className='w-full' />
																</div>
																<p className='text-sm'>{desc}</p>
															</div>
														)}

														{index === 1 && (
															<div className='flex items-center space-x-10 mt-6'>
																<div className='w-1/3'>
																	<Mouse className='w-full' />
																</div>
																<div className='w-auto'>
																	<p className='text-sm pr-16'>{desc}</p>
																</div>
															</div>
														)}
													</div>
												))}

										{state.step > 0 && slidesContent[state.step + 1].desc && (
											<>
												<div className='w-32 mx-auto flex justify-center space-x-2 my-8'>
													<Minus className='w-4' />
													{getIcon(
														slidesContent[state.step + 1].title.toLowerCase()
													)}
													<Minus className='w-4' />
												</div>
												<p className='text-sm'>
													{slidesContent[state.step + 1].desc.substring(0, 239)}
													.
												</p>
											</>
										)}
									</div>

									{/*Buttons & dots*/}
									<div className='absolute bottom-5 w-full'>
										<div className='flex justify-center space-x-3'>
											{state.step !== 0 && (
												<ThemeButton
													onClick={() => {
														dispatch({ type: TIPS.PREVIOUS });
													}}
													primary={false}>
													Previous
												</ThemeButton>
											)}

											{state.step !== slidesContent.length - 2 && (
												<ThemeButton
													onClick={() => {
														dispatch({ type: TIPS.NEXT });
													}}
													primary>
													Next Tip
												</ThemeButton>
											)}

											{state.step === slidesContent.length - 2 && (
												<ThemeButton
													onClick={() => setShowModal(false)}
													primary>
													Close
												</ThemeButton>
											)}
										</div>

										<div className='flex justify-center items-center space-x-1.5 mt-8'>
											{slidesContent &&
												slidesContent
													.slice(1)
													.map((item, index) => (
														<div
															key={`dot-${index + 1}`}
															className={`dot w-3 h-3 rounded-full cursor-pointer ${
																index === state.step
																	? "bg-white"
																	: "bg-gray-800"
															}`}></div>
													))}
										</div>
									</div>
								</div>

								{/*<div className='bg-white w-3/5'>
								{state.step > 0 && (
									<SlArrowLeft className='absolute text-black top-1/2 left-3 hover:text-gray-500 cursor-pointer'
										 onClick={() => {
										  	dispatch({ type: TIPS.PREVIOUS });
										 }}
									/>
								)}
								{copy[state.step].img}
							</div>

							<div className='w-2/5'>
								{state.step < copy.length-1 && (
									<SlArrowRight className='absolute top-1/2 right-3 hover:text-gray-500 cursor-pointer'
										  onClick={() => {
										  	dispatch({ type: TIPS.NEXT });
										  }}
									/>
								)}

								<div className='flex justify-end p-2'>
									<IoClose className='cursor-pointer' onClick={() => setShowModal(false)} />
								</div>

								<div className='px-16 pb-14'>
									{copy[state.step].text}

									<div className='flex justify-around space-x-2 mt-12'>
										{state.step !== 0 && (
											<ThemeButton
												onClick={() => {
													dispatch({ type: TIPS.PREVIOUS });
												}}
												primary={false}>
												Previous
											</ThemeButton>
										)}

										{state.step !== copy.length-1 && (
											<ThemeButton
												onClick={() => {
													dispatch({ type: TIPS.NEXT });
												}}
												primary>
												Next Tip
											</ThemeButton>
										)}

										{state.step === copy.length-1 && (
											<ThemeButton
												onClick={() => setShowModal(false)}
												primary>
												Close
											</ThemeButton>
										)}
									</div>

									<div className="flex justify-center items-center space-x-1.5 pt-8">
										{copy && copy.map((item, index) => (
											<div key={`dot-${index+1}`} className={`dot w-3 h-3 rounded-full cursor-pointer ${index === state.step ? 'bg-white': 'bg-gray-800'}`}></div>
										))}
									</div>
								</div>
							</div>*/}
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
}
