import Pubnub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { useEffect } from "react";
import uuid from "react-uuid";
import { CollapsiblePanel } from "../ui/CollapsiblePanel";
import MyChat from "./MyChat";
import { useGallery } from "../../store/GalleryProvider";
import { useCurrentUser } from "../../store/UserProvider";
import { HiChatBubbleLeft } from "react-icons/hi2";

const pubnub = new Pubnub({
	publishKey: process.env.REACT_APP_PUBNUB_PUB,
	subscribeKey: process.env.REACT_APP_PUBNUB_SUB,
	uuid: uuid(),
	// // userId: "2821d6a4-0b42-4df8-9606-c70d9f621628", // Mireya
	// userId: "0e4f8ec6-f328-45c9-a861-32e43528095f", // Mathilda89
	// // userId: uuid ? uuid : currentUser.data.uuid,
});

export const ChatComponent = () => {
	const { currentUser } = useCurrentUser();
	const { gallery } = useGallery();

	useEffect(() => {
		if (!!!currentUser) return;

		pubnub.objects.setUUIDMetadata({
			data: { email: currentUser.emailID, type: currentUser.userType },
		});

		// Link the current user to the default channel, if not linked yet
		pubnub.objects.setMemberships({
			uuid: currentUser.uuid,
			channels: ["gallery"],
		});
	}, [currentUser]);

	return (
		<CollapsiblePanel icon={HiChatBubbleLeft} className='fixed bottom-2 left-2'>
			<div className='mx-auto max-w-7xl rounded-2xl w-full'>
				<PubNubProvider client={pubnub}>
					<MyChat />
				</PubNubProvider>
			</div>
		</CollapsiblePanel>
	);
};
