export const APPLAYER_ORDER = {
	EXPERIENCE: "0",
	INTERFACE: "10",
	BLOCKING: "20",
	DEBUG: "50",
};

export function AppLayer({ children, order }) {
	return (
		<div
			className={`disable-container-pointer absolute left-0 top-0 w-screen h-screen z-${order}`}>
			{children}
		</div>
	);
}
